// This is specifically for the quantity buttons for the add to cart form
// - this is broke out alone on purpose
.product-add-form {

  .field.qty {
    max-width: 100%;
    margin-top: $global-margin / 3;
    width: 100% !important;

    @include breakpoint(medium) {
      width: 50% !important;
    }

    .control {
      width: 80% !important;
    }

    input {
      width: 40% !important;

      @include breakpoint(small only) {
        margin-bottom: $global-padding / 4;
      }
    }

    input[type=number] {
      -moz-appearance: textfield; // sass-lint:disable-line no-vendor-prefixes
    }

    input[type=number]::-webkit-inner-spin-button, // sass-lint:disable-line no-vendor-prefixes
    input[type=number]::-webkit-outer-spin-button {  // sass-lint:disable-line no-vendor-prefixes
      -webkit-appearance: none; // sass-lint:disable-line no-vendor-prefixes
      margin: 0;
    }
  }

  .qty-buttons {
    float: left;
    color: $primary-color;
    margin-bottom: $global-margin;

    @include breakpoint(medium) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  // simple increase and decrease quantity buttons
  .value-button {
    font-size: rem-calc(15);
    display: inline-block;
    border: 1px solid $grey;
    margin: 0 0 0 ($global-margin / -5);
    width: $global-padding * 3;
    height: $global-padding * 2.2;
    text-align: center;
    vertical-align: middle;
    padding: ($global-padding / 4) 0;
    background: $white;
    user-select: none;
    transition: color 400ms ease, background-color 400ms ease, border 400ms ease;

    @include breakpoint(medium) {
      font-size: $global-font-size;
      width: $global-padding * 2;
      height: $global-padding * 1.1;
      padding: inherit;
      line-height: 1;
    }

    &:hover {
      cursor: pointer;
      background-color: $primary-color;
      color: $white;
      border-color: $primary-color;
    }
  }
}
