.sendfriend-product-send {

  .fieldset {

    &.recipients {

      br {
        display: none;
      }
    }
  }

  .actions-toolbar {

    .primary {
      float: right;
    }

    .secondary {

      > a {
        @include button;
      }
    }
  }
}
