////////////////////////////////////////////////////////////////////////////////
// MIXINS
// -----------------------------------------------------------------------------

//////////////////
//button base styles
//////////////////
@mixin button-base {
  display: inline-block;
  font-size: rem-calc(16);
  white-space: nowrap;
  font-weight: 700;
  font-family: $body-font-family;
  line-height: $global-lineheight;
  text-align: center;
  text-transform: uppercase;
  background-color: $secondary-color;
  color: $white;
  border: 1px solid $secondary-color;
  border-radius: 0;
  padding: ($global-padding * 0.625) ($global-padding * 1.5625);
  margin: 0;
  cursor: pointer;
  transition: background-color 200ms, border 200ms, color 200ms;

  &:visited,
  &:active,
  &:focus {
    color: $white;
  }

  &:hover {
    background-color: darken($secondary-color, 10%);
    border-color: darken($secondary-color, 10%);
    color: $white;
  }
}

//////////////////
//button - 4 style combinations
@mixin button($outline: false, $reverse: false, $large: false, $alt: false) {
  @include button-base;

  @if $large == true {
    font-size: rem-calc(15);
    padding: ($button-padding * 0.75) ($button-padding * 2.25);
  }

  @if $outline == true {
    background-color: transparent;
    color: $primary-color;

    &:visited,
    &:active,
    &:focus {
      color: darken($primary-color, 15%);
    }

    &:hover {
      background-color: darken($primary-color, 15%);
      color: darken($white, 15%);
    }
  }

  @if $reverse == true {
    background-image: none;
    border-color: $primary-color;
    border-radius: 0;
    background-color: $primary-color;
    color: $white;

    &:visited,
    &:active,
    &:focus {
      color: $white;
    }

    &:hover {
      background-image: none;
      background-color: darken($secondary-color, 10%);
      border-color: darken($secondary-color, 10%);
    }

    @if $outline == true {
      background-color: transparent;
      color: $white;
      border: 1px solid rgba($white, 0.5);

      &:visited,
      &:active,
      &:focus {
        color: $white;
      }

      &:hover {
        background-color: $blue-light;
        border-color: rgba($blue-light, 0.6);
        color: $white;
      }
    }
  }

  @if $alt == true {
    background-image: none;
    border-color: transparent;
    border-radius: 0;
    background-color: transparent;
    color: $secondary-color;
    text-transform: none;

    &:visited,
    &:active,
    &:focus {
      color: $secondary-color;
    }

    &:hover {
      background-image: none;
      color: $primary-color;
      background-color: transparent;
      border-color: transparent;
    }
  }

  @content;
}
