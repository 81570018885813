.block-new-products.grid {

  .block-title {
    visibility: hidden;
    font-size: 0;
    height: 0;
  }

  .products-grid.grid {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  ol.product-items {

    @include breakpoint(large) {
      display: flex;
      justify-content: stretch;
      -ms-flex-pack: justify;
    }

    li {
      width: 100%;
      padding-right: 0 !important;
      padding-left: 0 !important;
      min-height: auto;
      // margin-bottom: $global-margin * 2;

      @include breakpoint(large) {
        width: 30.66667%;
        padding-right: ($global-padding * 2) !important;
        display: flex;

        &:first-child {
          padding-left: 0 !important;
        }
      }

      &:last-child:not(:first-child) {
        float: left;
        padding-right: 0 !important;
      }

      .product-item-info:first-child {
        background-color: $white;
        width: auto;
        padding: $global-padding * 1.125 !important;
      }

      .product-item-info:first-child:hover {
        box-shadow: 0 0 18px scale-color($grey-shadow, $alpha: -64%);
      }
    }

    .product-item-details {

      .price-box {

        .special-price,
        .old-price {
          display: block;

          .price-label {
            margin-right: $global-margin / 4;
          }
        }

        .special-price {
          color: $red;
        }

        .old-price {
          text-decoration: line-through;
          color: $grey;
          font-size: $global-font-size * 0.9;
        }
      }

      .price {
        font-weight: 700;
        font-size: $global-font-size * 1.125;
        margin-bottom: $global-margin / 2;
      }

      .product-item-link {
        color: $secondary-color;
        display: block;
        margin-bottom: $global-margin / 2;
        font-weight: 500;
      }

      .product-item-link:hover {
        color: $grey-dark;
      }

      .product-item-description {
        display: block;
        color: $grey;
        font-size: $global-font-size * 0.85;
        font-weight: 500;
      }
      .product-reviews-summary{
        .action{
          display: block;
          clear: both;
        }
      }
    }
  }
}
