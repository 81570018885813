.sidebar {

  > .block {

    > .title,
    > .block-title {
      color: $secondary-color;
      font-size: $global-font-size * 1.25;
      padding: ($global-padding) ($global-padding * 1.5);
      font-weight: 700;
      text-transform: uppercase;
      background-color: scale-color($secondary-color, $alpha: -80%);
      margin-bottom: $global-margin * 2;
    }

    > .content {
      background-color: $white;
      padding: ($global-padding) ($global-padding / 2) ($global-padding / 2);
      margin-bottom: $global-margin * 2;
    }
  }
}
