.main-content {
  background-color: $white;
  padding: ($global-padding * 1.25) ($global-padding * 1.25);
  padding-bottom: 0;
  clear: both;

  p {
    padding-bottom: $global-padding * 1.125;
    margin-bottom: 0;
  }
}