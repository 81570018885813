.catalog-product-view {

  .review-list {
    margin-bottom: $global-margin;
    border-bottom: 1px solid $grey-light;
    padding-bottom: $global-padding;

    .review-items {
      list-style: none;
      margin-left: 0;
    }

    .review-item {
      margin: 0 ($global-margin * 2);
      padding: ($global-padding) 0;
      border-bottom: 1px solid $secondary-color;

      .review-title {
        font-weight: bold;
        font-size: $global-font-size * 1.2;
        color: $primary-color;
      }

      .review-content {
        margin-bottom: $global-margin;
      }

      .review-details {

        p {
          display: inline-block;
          margin-right: $global-margin;
        }
      }
    }
  }

  .review-add {
    margin-top: 0;

    .fieldset.review-fieldset {
      margin-top: 0;

      legend {
        text-align: left;
        margin-bottom: $global-margin * 3;

        span,
        strong {
          display: inline;
          margin-right: $global-margin / 2;
          text-transform: uppercase;
        }
      }
    }

    .actions-primary {
      float: none;

      button {
        @include button($outline: false, $reverse: false, $large: false);
      }
    }
  }

  .product-social-links {

    // Interdry star ratings in ratings-summary hidden
    // stars not associated with Interdry, BUT they are associated with HDIS pages
    .rating-summary {
      display: none;
    }

    .reviews-actions {

      > a {
        margin-right: 0;

        @include breakpoint(medium) {
          float: left;
          margin-right: $global-margin;
        }
      }
    }
  }
}

.review-customer-view  {

  .customer-review.view {
    clear: both;
    background-color: $white;
    padding: $global-padding;
    margin-bottom: $global-margin;
  }  
}
