// Default contact form
.form.contact {
  @include grid-column;
  padding-left: 0 !important;
  padding-right: 0 !important;

  fieldset {
    margin-top: $global-margin * 0.15;

    br,
    legend {
      display: none;
    }

    .field.note {
      margin: $global-margin 0;
    }
  }
}
