//Category CMS pages
.category-cms {
  background-color: $white;
  padding: $global-padding * 1.25;
  clear: both;

  p {
    padding-bottom: $global-padding * 1.125;
    margin-bottom: 0;
  }
}
