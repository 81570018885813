.subscription {

  &.table-wrapper {

    .input-text.qty {
      border: 0;
    }

    .actions-toolbar {
      display: none;
    }

    .product-name {

      .product-sku {
        display: block;
        font-weight: 600;
      }
    }
  }
}

.block-subscription-info {

  .box {
    margin-bottom: 30px;
  }

  .item-options {

    dt {
      clear: left;
      display: inline-block;
      float: left;
      width: 40%;
    }
  }
}

.subscription-actions-toolbar {
  float: right;

  .action.primary {
    float: right;
  }

  .action.secondary {
    padding: 7px 15px 7px 0;
    vertical-align: middle;
  }
}
