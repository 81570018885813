body {
  margin: 0;
  padding: 0;
  background-color: $body-background;
}

main {
  max-width: 1921px;
  margin: 0 auto;

  > .columns {
    padding: 0;

    > .column.main {
      padding: 0 !important;
    }
  }
}

.page-layout-1column,
.page-layout-checkout,
.checkout-index-index {

  .breadcrumbs {
    @include grid-row;
    float: none;
    padding: 0;

    > * {
      @include grid-column(12);
    }
  }

  .page-main {
    @include grid-row;
    float: none;
    padding: 0;

    > * {
      @include grid-column(12);
    }

    > .main {
      margin-top: $global-margin * 2;
    }

    .page-title-wrapper {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.page-layout-1column-fullwidth {

  .page-main {
    display: inline-block;
    margin-bottom: $global-margin * 2;
  }

  .page-footer {
    clear: both;
  }
}

.page-layout-2columns-left,
.page-layout-2columns-right {

  .breadcrumbs {
    @include grid-row;
    float: none;
    padding: 0;

    > * {
      @include grid-column(12);
    }
  }

  .page-main {
    @include grid-row;
    float: none;
    padding: 0;

    > * {
      @include grid-column(12);
    }

    .main {
      @include grid-column(12);
      margin-top: $global-margin * 2 !important;

      @include breakpoint(medium) {
        @include grid-column(9);
        // @include grid-column-position(3);
        padding: 0 !important;
      }

      .page-title-wrapper {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .sidebar {
      @include grid-column(12);
      margin-top: $global-margin * 2;
      padding-left: 0 !important;

      @include breakpoint(medium) {
        @include grid-column(3);
        // @include grid-column-position(-9);
      }
    }
  }

  &.page-products {

    .page-main {
      max-width: $grid-row-narrow;
    }
  }
}
@media screen and (max-width: 1024px) {
body.page-products.catalog-category-view .main .category-cms {
  display:block !important;
}
}

