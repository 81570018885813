//Site Footer
footer {
  background-color: $white;
  box-shadow: 0 0 13px scale-color($black, $alpha: -82%);

  > .footer.content {
    @include grid-row;
    padding: 0;

    > .footer-col {
      @include grid-column(12);

      @include breakpoint(medium) {
        @include grid-column(3);
      }

      &:last-child:not(:first-child) {
        float: left;
      }

      > h4 {
        text-transform: uppercase;
        font-weight: 700;
        font-size: $global-font-size * 1.25;

        @include breakpoint (small only) {
          padding-top: $global-padding / 2;
          padding-bottom: $global-padding / 2;
          cursor: pointer;
          @include icon($position: after, $icon: plus) {
            margin-left: $global-margin;
          };
        }

        &.open {
          @include icon($position: after, $icon: minus) {
            // margin-left: $global-margin;
          };
        }

        @include breakpoint(medium) {
          margin-bottom: $global-margin * 2.1875;
        }
      }
    }

    .footer-col-content {

      @include breakpoint(small only) {
        transition: visibility 500ms ease, opacity 200ms ease, max-height 500ms ease;
        height: auto;
        max-height: rem-calc(300);
        visibility: visible;
        opacity: 1;

        &:not(.open) {
          max-height: 0;
          visibility: hidden;
          opacity: 0;
        }
      }

      > ul {
        list-style: none;
        margin-left: 0;

        li {
          padding-bottom: $global-padding * 1.375;
        }
      }
    }

    a {
      color: $grey;
      font-weight: 600;

      &:hover {
        color: $secondary-color;
      }
    }

    .title {
      text-transform: uppercase;
      font-weight: 700;
      font-size: $global-font-size * 1.25;
      margin-bottom: $global-margin * 2.1875;
    }

    > .block.newsletter {
      @include grid-column(12);

      @include breakpoint(medium) {
        @include grid-column(3);
        padding-right: 0 !important;
        float: right;
      }

      .label {
        visibility: hidden;
        font-size: 0;
        width: 0;
        height: 0;
      }

      input {
        border-radius: 0;
      }

      button {
        @include button;

        > span::after {
          content: '!';
        }
        &.subscribe{
          position: relative;
          width: auto;
          height: auto;
        }
      }
    }

    > .bugs {
      display: none;
    }
  }

  // Footer Bottom
  > .bottom-footer {
    @include grid-row;
    margin-top: $global-margin * 2;
    border-top: 1px solid $grey-light;
    font-size: $global-font-size * 0.8;
    color: $grey;
    padding-top: $global-padding;
    padding-bottom: $global-padding;


    > .block-left,
    > .block-right {
      @include grid-column;

      @include breakpoint(medium) {
        @include grid-column(6);
      }
    }

    > .block-left {
      padding-left: 0;

      img {
        float: left;
      }
    }

    > .block-right {
      padding-right: 0;

      @include breakpoint(small only) {
        margin-top: $global-margin;
      }

      @include breakpoint(medium) {
        text-align: right;
      }
    }
  }
}
