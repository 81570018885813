//Shopping cart and shopping cart modal on checkout page
.cart-container,
.items-in-cart {

  [aria-selected='false'] {
    @include icon($position: before, $icon: chevron-right) {
      margin: $global-margin / 2;
    };
  }

  [aria-selected='true'] {
    @include icon($position: before, $icon: chevron-down) {
      margin: $global-margin / 2;
    };
  }

  table {

    .subscription-additional-info {
      clear: both;
      display: block;
    }
  }
}

.checkout-cart-index .cart-container .cart-summary .cart-totals {

  .totals {

    .mark > .title {
      background: none;
      color: $black;
      padding: 0;
    }
  }
}
