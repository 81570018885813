[type="submit"],
[type="submit"]:disabled,
[type="button"],
button:not(.js-hide),
.button:not(.js-hide),
.button.primary:not(.js-hide),
.button {
  @include button;
}

.alt[type="submit"]:not(.js-hide),
.alt[type="submit"]:disabled:not(.js-hide),
.alt[type="button"]:not(.js-hide),
.button.secondary:not(.js-hide),
.button.alt:not(.js-hide) {
  @include button($outline: true);
}

[type="submit"]:disabled {
  background-color: transparent;
  color: $primary-color;

  &:hover {
    background-color: transparent;
    color: $grey-light;
  }
}

//Toolbar links to display inline-block
.actions-toolbar {
  
  .primary,
  .secondary {
    display: inline;

    //Add button styles to primary or secondary links to match buttons of primary links.
    // a,
    // button {
    //   margin-top: $global-margin;
    // }
  }
}
