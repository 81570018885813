.page-header {
  background-color: $white;
  box-shadow: 0 6px 6px scale-color($black, $alpha: -92%);
  margin-bottom: $global-margin * 2 !important;
  position: relative;

  .panel.wrapper {
    display: none;
  }

  > .header.content {
    @include grid-row;
    position: relative;
    padding: 0 !important;

    > .logo {
      @include grid-column(11);
      text-align: left;
      padding-top: $global-padding;
      margin-top: 0 !important;
      margin-bottom: 0 !important;

      @include breakpoint(medium) {
        @include grid-column(4);
      }

      @include breakpoint(large) {
        @include grid-column(3.5);
      }

      img {
        width: $global-padding * 12;
        max-width: none;
        vertical-align: top;
        margin: ($global-margin / 2) 0 ($global-margin * 2);

        @include breakpoint(large) {
          margin-top: $global-margin * 0.75;
          margin-bottom: 0;
          max-width: 65%;
          width: auto;
        }
      }
    }

    > .header-phone {
      padding-top: $global-padding * 1.75;
      color: $primary-color;
      font-size: $global-font-size * 1.5;
      max-width: 70%;
      text-align: center;
      font-weight: 800;

      @include breakpoint(small only) {
        margin: $global-margin;
        max-width: 100%;
      }
    }

    > .minicart-wrapper {
      @include grid-column(2);
      margin: ($global-margin * 1.75) 0;
      padding: 0;
      text-align: center;
      position: absolute;
      right: $global-padding * 2;
      top: auto;
      float: right !important;
      z-index: 10;

      @include breakpoint(medium) {
        text-align: left;
        right: 0;
      }

      @include breakpoint(large) {
        @include grid-column(1);
        position: relative;
      }
    }

    .action.nav-toggle {
      display: none;
    }

    .header-links-container {
      position: relative;
      cursor: pointer;

      > .icon.menu {
        font-family: fontawesome;
        font-size: $global-padding * 1.5;
        color: $grey;
        display:block;
        position: absolute;
        top: $global-padding * -3.5;
        right: $global-padding * 2; 

        @include breakpoint(medium) {
          top: $global-margin * -2.2;
          right: $global-margin * 10.5;
        }

        @include breakpoint(large) {
          right: $global-margin * 1.5;
          right: $global-margin * 7.5;
        }

        &.close {
          content: '\f00d';
        }
      }
    }

    .header.links {
      visibility: hidden;
      opacity: 0;
      background-color: $white;
      color: $grey-dark;
      font-size: $global-font-size * 0.875;
      box-shadow: 0 0 13px scale-color($black, $alpha: -85%);
      width: 100%;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 15;
      overflow: hidden;
      padding: ($global-padding * 0.3125) ($global-padding * 1.625) ($global-padding * 0.5625);
      transition: visibility 300ms ease, opacity 300ms ease;

      @include breakpoint(medium) {
        width: rem-calc(270);
        top: $global-padding;
        right: $global-padding * 7;
      }

      li {
        display: block;
        margin-top: $global-margin;
        font-size: $global-font-size;
      }

      li a {
        color: $grey;
        font-weight: 500;
        display: block;
        transition: color 300ms ease;

        &:hover {
          color: $secondary-color;
        }
      }

      li:nth-child(1) a {
        @include icon($position: before, $icon: user, $icon-set: 'fontawesome') {
          color: $grey;
          font-size: $global-font-size * 1.5;
          margin-right: $global-margin;
        };
      }

      li:nth-child(1) a:hover {
        @include icon($position: before, $icon: user, $icon-set: 'fontawesome') {
          color: $secondary-color;
        };
      }

      li.link.wishlist a {
        @include icon($position: before, $icon: check-circle, $icon-set: 'fontawesome') {
          color: $grey;
          font-size: $global-font-size * 1.5;
          margin-right: $global-margin;
        };
      }

      li.link.wishlist a:hover {
        @include icon($position: before, $icon: check-circle, $icon-set: 'fontawesome') {
          color: $secondary-color;
        };
      }

      li:nth-child(2) a {
        @include icon($position: before, $icon: user-add, $icon-set: 'fontawesome') {
          color: $grey;
          font-size: $global-font-size * 1.5;
          margin-right: $global-margin;
        };
      }

      li:nth-child(2) a:hover {
        @include icon($position: before, $icon: user-add, $icon-set: 'fontawesome') {
          color: $secondary-color;
        };
      }

      li.authorization-link a {
        @include icon($position: before, $icon: lock, $icon-set: 'fontawesome') {
          color: $grey;
          font-size: $global-font-size * 1.5;
          margin-right: $global-margin;
        };
      }

      li.authorization-link a:hover {
        @include icon($position: before, $icon: lock, $icon-set: 'fontawesome') {
          color: $secondary-color;
        };
      }

      .greet.welcome {
        font-size: $global-font-size;
        color: $secondary-color;
        border-bottom: 1px solid $grey-light;
        border-top: 1px solid $grey-light;
        margin: ($global-margin * 1.25) ($global-margin * -1.6875);
        padding: ($global-padding * 1.25) ($global-padding * 1.6875);
      }

      &.open {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.cms-home {

  .page-header {
    margin-bottom: 0 !important;
  }
}

.checkout-index-index {

  header {

    > .header.content {

      > .logo {
        float: left !important;
      }
    }
  }
}
