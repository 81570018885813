// Customer Account Orders View Page
.sales-order-view {

  .order-actions-toolbar {

    .actions {

      a {
        @include button($reverse: true);
        margin-top: $global-margin;
      }
    }
  }
}
