// Account creation page
.customer-account-create {

  .page-main {

    .action.back {
      @include button($reverse: true);
    }
  }
}


// Customer account login form block
.customer-account-login {

  .login-container {

    fieldset {
      @include breakpoint(small only) {
        padding-left: $global-padding;
        padding-right: $global-padding;
      }
    }
  }
}

.customer-account-forgotpassword {
  .page-wrapper .page-main .main{
    float: left!important;
    left: 0;
  }
}

@media screen and (max-width: 1200px) and (min-width: 768px){
  body.customer-account-forgotpassword {
    .page-wrapper .page-main .main{
      left: 5%;
    }
  }
}