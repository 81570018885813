
.page-title,
.category-title {
  color: $secondary-color;
  font-size: $global-font-size * 1.25;
  padding: ($global-padding) ($global-padding * 1.5);
  font-weight: 700;
  text-transform: uppercase;
  background-color: scale-color($secondary-color, $alpha: -80%);
  margin-bottom: $global-margin * 2;
}

.widget-categories.block {

  .block-title {
    background-color: $secondary-color;
    color: $white;
    font-weight: 700;
    text-transform: uppercase;
    font-size: $global-font-size * 1.25;
    padding: ($global-padding) ($global-padding * 1.5);
    margin-bottom: $global-margin * 1.85;
  }

  .widget-inner.block-content {
    background-color: $white;

    ul {
      list-style: none;

      li {
        padding: 0 ($global-padding * 0.75) 0 ($global-padding / 2);
        margin-bottom: $global-margin;
      }

      li a {
        color: $grey;
        font-weight: 700;
        text-transform: capitalize;
      }

      a:hover {
        color: $grey-dark;
      }
    }
    .carousel-inner{
      .item{
        padding: ($global-padding * 1.25) 0;
        .categories-row{
          margin: 0;
        }
      }
    }
  }
}
