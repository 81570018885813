
.block.items-in-cart {

  .minicart-items {
    list-style: none;

    li {

      .subtotal {
        // font-weight: bold;
        font-size: $global-font-size * 1.3;
        color: $secondary-color;
      }
    }
  }
}
