.catalogsearch-result-index {

  .list.products-list {
    @include grid-row;
    margin-bottom: $global-margin * 2;
    padding-bottom: $global-padding;
    background-color: $white;
    padding-left: $global-padding;
    padding-right: $global-padding;

    .product-items {
      margin-left: 0;

      li:not(:last-child) {
        padding-bottom: $global-padding;
        border-bottom: 1px solid rgba($secondary-color, 0.2);
      }

      .product-item-info {
        @include grid-row;

        .product-item-photo {
          @include grid-column(3);
          padding-left: 0 !important;
          padding-right: 0 !important;
        }

        .product-item-details {
          @include grid-column(8);
          padding-left: 0 !important;
          padding-top: $global-padding * 2;
        }
      }
      
      .product-item-actions {
        margin-top: $global-margin;
        margin-bottom: $global-margin;

        > *:not(.actions-primary) {
          display: none;
        }
      }

      .product-item-description {

        .action.more {
          display: none;
        }
      }
    }

    .actions-toolbar {
      @include grid-column(12);
      padding-bottom: $global-padding;

      .primary {

        > button {
          width: 100%;
          margin-bottom: $global-margin;

          @include breakpoint(medium) {
            width: 30%;
            margin-left: 3%;
            float: left;
          }
        }
      }

      .secondary {
        display: none;
      }
    }
  }  
}

body {
  .product-add-form .box-tocart .value-button {
    height: 1.5rem;
    line-height: 1.5;
    border-radius: 0 4px 0 0;
    &.decrease {
      border-radius: 0 0 4px 0;
    }
  }
}

@media only screen and (max-width: 800px) {
  body {
    .product-add-form .box-tocart .value-button {
      height: 3rem;
      line-height: 3rem;
      border-radius:4px;
      padding: 0;
      &.decrease {
        border-radius:4px;
      }
    }
  }
}