
.account.sales-order-print {

  .order-title,
  .payment-method .title {
    display: none;
  }

  .items-qty {
    list-style-type: none;
    margin-left: 0;
  }
}
