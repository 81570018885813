body,
html {
  font-size: 100%;
  font-family: 'Roboto', sans-serif;
  font-smoothing: antialiased;
  color: $body-font-color;
}

h1,
h2 {
  font-family: 'Roboto', sans-serif;
}

h2 {
  font-size: $global-font-size * 1.125;
  font-weight: 700;
  margin-bottom: $global-margin * 1.125;
}

h3 {
  font-size: $global-font-size;
  font-weight: 700;
  line-height: 1.25;
}

a {
  color: $secondary-color;
  transition: color 500ms ease;

  &:hover {
    color: $primary-color;
  }
}
