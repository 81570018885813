// Product image gallery
.fotorama__stage {
  margin-bottom: $global-margin;
  border: 1px solid $grey-light;
}

.fotorama__nav__shaft {
  display: inline-block;
}

.fotorama__thumb-border {
  border: 1px solid $secondary-color;
}
