// Form styling overrides
// base styles in HDIS Default
.field {

  input {

    &.mage-error {
      margin-bottom: 0;
    }
  }
}

