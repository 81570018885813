.ves-widget.widget-images.block {
  margin-bottom: $global-margin * 1.875;

  .image-description {
    visibility: hidden;
    font-size: 0;
    width: 0;
    height: 0;
  }
}

.home {

  > .ves-container:first-of-type {

    .ves-inner {

      > .row {
        max-width: 100%;
        max-width: 100vw;
      }
    }
  }
}
