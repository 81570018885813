// Checkout page
.checkout-index-index {

  // Login button if logged out guest
  .authentication-wrapper {
    top: $global-padding * 2.33 !important;
  }

  // Checkout sidebar
  .opc-sidebar {
    margin-top: $global-margin * 3;

    > .modal-inner-wrap .modal-content {
      
      .totals {

        .mark > .title {
          background: none;
          color: $black;
          font-size: 1rem;
          padding: 0;
          text-transform: capitalize;
        }
      }
    }
  }
}
