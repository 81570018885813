.ves-container.promo-callouts{ 

  .row-inner {
    @include grid-column;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-bottom: $global-margin * 2;

    > div {
      @include grid-column(12);
      background-color: $primary-color;
      color: $white;
      display: block;
      padding: ($global-padding * 2) 0;
      text-align: center;
      margin-bottom: $global-margin;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include breakpoint(medium only) {
        min-height: rem-calc(230);
      }

      @include breakpoint(medium) {
        @include grid-column(5.75);
      }

      h4 {
        font-size: $global-font-size * 2.8125;
        font-weight: 500;
        line-height: $global-font-size * 2.8125;
      }

      h4 small {
        font-size: $global-font-size * 1.5;
        line-height: $global-font-size * 1.5;
        display: block;
        color: $white;
        margin-top: $global-margin;
      }
    }
  }
}
