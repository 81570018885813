//Customer Account Dashboard
.account {

  a {

    + a {
      border-left: 1px solid $grey-light;
      margin-left: $global-margin / 2;
      padding-left: $global-padding;
    }
  }
}
