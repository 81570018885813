.toolbar {
  @include grid-row;

  .modes {

    .mode-grid,
    .mode-list {
      background-color: $grey-light;
      box-shadow: inset 0 1px 0 0 $grey, inset 0 -1px 0 0 rgba($grey, 0.3);
      border: 1px solid $secondary-color;
      line-height: 1;
      padding: ($global-padding / 3) ($global-padding / 2);
      text-align: center;
      display: inline-block;
      text-decoration: none;

      span {
        font-size: 0;
        width: 0;
      }

      &.active {
        box-shadow: inset 0 1px 0 0 rgba($grey, 0.8), inset 0 -1px 0 0 rgba($grey, 0.3);
        background: darken($grey-light, 10%);
        color: $grey-dark;
      }
    }

    .mode-grid {
      @include icon($position: before, $icon: grid, $icon-set: 'fontawesome') {
        font-size: $global-font-size * 1.5;
      };
    }

    .mode-list {
      @include icon($position: before, $icon: list, $icon-set: 'fontawesome') {
        font-size: $global-font-size * 1.5;
      };
      margin-left: $global-margin / -4;
    }
  }

  .field.limiter,
  .limiter,
  .toolbar-sorter.sorter   {
    float: left;
    display: table;

    > * {
      display: table-cell;
    }

    >:first-child {
      padding-right: $global-padding;
    }

    .limiter-text {
      padding-left: $global-padding;
    }

  }

  .toolbar-sorter.sorter  {
    float: right;
    
    .sorter-action {
      padding-left: $global-padding;

      &.sort-asc,
      &.sort-desc {

        span {
          font-size: 0;
          width: 0;
        }
      }

      &.sort-asc {
        @include icon($position: before, $icon: arrow-down, $icon-set: 'fontawesome') {
          font-size: $global-font-size * 1.5;
        };
      }

      &.sort-desc {
        @include icon($position: before, $icon: arrow-up, $icon-set: 'fontawesome') {
          font-size: $global-font-size * 1.5;
        };
      }
    }
  }

  &.order-products-toolbar {

    .toolbar-amount {
      position: inherit;
      display: block;
    }
  }

  &:after {
    clear: both;
  }

  // toolbar amount can be hidden on subscriptions toolbar
  .customer-subscriptions-index & {

    .toolbar-amount {
      display: none;
    }
  }
}
